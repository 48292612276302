var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "300" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "u-blue-text" }, [
            _c("p", [
              _c("i", { staticClass: "mdi mdi-clock" }),
              _vm._v(" " + _vm._s(_vm.$t("SelectAvailableTime"))),
            ]),
          ]),
          _c(
            "v-card-text",
            {},
            [
              _c("v-combobox", {
                attrs: {
                  items: _vm.hourRange,
                  label: "Time",
                  outlined: "",
                  chips: "",
                  dense: "",
                },
                on: { change: _vm.timeAccepted },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "green darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("time-selected", true)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("SaveCapital")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }