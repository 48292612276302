<template>
<v-row class="ml-4 ml-sm-0">
<p class="u-blue-text col-12 d-block d-sm-none" style="padding: 0px; margin-top: 5px;">{{AppointmentDateFormatted}}</p>
<v-layout>
 <datepicker v-model="date" ref="datepick" :disabled-dates="disabledDates"
            :key="disabledDates.length"
            :firstDayOfWeek="1"
            @closed="triggerTimeCoponent()"
            @input="changeDate()">
            <div slot="beforeCalendarHeader" class="calender-header u-blue-text text-center" >
                {{ $t('ChooseAppointmentDate') }}
            </div>
 </datepicker>
 <v-text-field outlined class="d-none d-sm-block"
 readonly :disabled="!this.$store.state.appointment.isAppointmentDateVisible"
  v-model="AppointmentDateFormatted"
 dense>
 </v-text-field>
 <v-btn large @click="openDatePicker" :disabled="!this.$store.state.appointment.isAppointmentDateVisible"
 v-if="isTowingService"
    class="u-background-primary text--white
    theme--dark button-on-input text-uppercase">
    {{ $t('ScheduleAPickup') }}
</v-btn>
<v-btn large @click="openDatePicker" :disabled="!this.$store.state.appointment.isAppointmentDateVisible"
 v-if="!isTowingService"
    class="u-background-primary text--white
    theme--dark button-on-input text-uppercase">
     {{ $t('ScheduleAnAppointment') }}
</v-btn>


<custom-time-picker-v2
  :dialog="showTimePicker"
  :hourRange="hourRange"
  @time-selected="closeTimeDialog">
</custom-time-picker-v2>
</v-layout>
</v-row>

</template>

<script>
import Datepicker from 'vuejs-datepicker';
import SessionStorageHelper from '@/store/helper/SessionStorageHelper';
import moment from 'moment';
import CustomTimePickerV2 from './CustomTimePickerV2.vue';


export default {
  name: 'CustomDatePicker',
  components: {
    Datepicker,
    CustomTimePickerV2,
  },
  data() {
    return {
      format: 'dd MMMM yyyy',
      disabledDates: {
        to: new Date(),
        customPredictor: (date) => {
          const slots = JSON.parse(localStorage.getItem('availableSlots'));
          const datesAvailables = Object.keys(slots);
          for (let index = 0; index < datesAvailables.length; index += 1) {
            const element = datesAvailables[index];
            if (date === undefined) return true;
            if (element === moment(date).format('MM/DD/YYYY')) {
              return false;
            }
          }
          return true;
        },
      },
      date: '',
      language: 'en',
      hourRange: [],
      dates: [],
      displayTimePicker: false,
      showTimePicker: false,
    };
  },
  props: {
    availableSlots: Object,
    isDatePickerDisabled: Boolean,
    isTowingService: Boolean,
  },
  // watch: {
  //   // showTimePicker: (newVal) => {
  //   //   this.showTimePicker = newVal;
  //   // },
  //   // isDatePickerDisabled: (newVal) => {
  //   //   this.isDatePickerDisabled = newVal;
  //   // },
  // },
  computed: {
    AppointmentDateFormatted() {
      return moment(this.$store.state.appointment.appointment.appointmentDate).format('MM/DD/YYYY, h:mm a');
    },
  },
  beforeCreate() {
    const mobileData = SessionStorageHelper.getSessionData('mobile-service');
    if (mobileData !== null && mobileData.step2.address.date !== '') {
      this.date = mobileData.step2.address.date;
      this.$store.dispatch('appointment/changeAppointmentDate', this.date);
    } else {
      const towingData = SessionStorageHelper.getSessionData('towing-service');
      if (towingData !== null && towingData.step1.address.date !== '') {
        this.$store.dispatch('appointment/changeAppointmentDate', towingData.step1.address.date);
      }
    }
  },
  methods: {
    openDatePicker() {
      this.$refs.datepick.showCalendar();
      this.showTimePicker = false;
    },
    triggerTimeCoponent() {
      this.$refs.datepick.popupCalendar = false;
      const dateSelected = moment(this.$store.state.appointment.appointment.appDate).format('MM/DD/YYYY');

      if (this.$store.state.appointment.availableSlots.availableDates === undefined) {
        this.hourRange = this.$store.state.appointment.availableSlots[dateSelected];
      } else {
        this.hourRange = this.$store.state.appointment.availableSlots.availableDates[dateSelected];
      }
      this.showTimePicker = true;
    },
    changeDate() {
      this.$store.dispatch('appointment/changeAppDate', this.date);
      const mobileData = SessionStorageHelper.getSessionData('mobile-service');
      const towingData = SessionStorageHelper.getSessionData('towing-service');
      if (mobileData !== null) {
        mobileData.step2.address.date = this.date;
        SessionStorageHelper.saveData('mobile-service', mobileData);
      } else if (towingData !== null) {
        towingData.step1.address.date = this.date;
        SessionStorageHelper.saveData('towing-service', towingData);
      }
    },
    getDates(startDate, stopDate) {
      const dateArray = [];
      let currentDate = moment(startDate);
      const stopDates = moment(stopDate);
      while (currentDate <= stopDates) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(1, 'days');
      }
      return dateArray;
    },
    closeTimeDialog(display) {
      this.showTimePicker = !display;
    },
  },
};
</script>

<style>
.vdp-datepicker input{
  padding: 0.75em 0.5em;
  font-size: 100%;
  border: 1px solid #ccc;
  width: 100%;
  display: none;
}

select {
  height: 2.5em;
}

.example {
  background: #f2f2f2;
  border: 1px solid #ddd;
  padding: 0em 1em 1em;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

h5 {
  font-size: 100%;
  padding: 0;
}

.form-group {
  margin-bottom: 1em;
}

.form-group label {
  font-size: 80%;
  display: block;
}
</style>
