<template>
<v-dialog
      v-model="dialog"
      max-width="300">
      <v-card>
        <v-card-title class="u-blue-text"><p><i class="mdi mdi-clock"></i>
        {{ $t('SelectAvailableTime') }}</p></v-card-title>
        <v-card-text style="">
            <v-combobox
            v-model="time"
            :items="hourRange"
            label="Time"
            outlined
            chips
            dense
            @change="timeAccepted"
            ></v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="$emit('time-selected', true)">
            {{ $t('SaveCapital') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>

export default {

  data() {
    return {
      time: '',
    };
  },
  props: {
    dialog: Boolean,
    hourRange: Array,
  },
  methods: {
    timeAccepted() {
      this.$store.dispatch('appointment/changeAppTime', this.time.split('-')[0]);
      this.time = '';
    },
    // setDisplay(value) {
    //   this.showDialogs = value;
    // },
  },
  // watch: {
  //   dialog: (newVal) => {
  //     this.setDisplay(newVal);
  //   },
  // },
};
</script>
